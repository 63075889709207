import React from "react";

import { FormControl, FormHelperText } from "@material-ui/core";

import styled from "styled-components";
import { withTheme } from "@material-ui/styles";

import { FieldProps, useFormFull } from "form-full";
import { Spacing } from "../../config";
import FontStyles from "../styles/fontStyles";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

export const Label = styled.p(({ theme, $withError, $color, white }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.semibold14,
    textTransform: "uppercase",
    padding: 0,
    margin: 0,
    color: $withError ? colors.error.main : "white",
    transition: ".2s",
    pointerEvents: "none",
    alignItems: "center",
    display: "flex",
    overflow: "hidden",
  };
});

export const LabelView = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignContent: "center",
    justifyContent: "flex-start",
  };
});

const StyledInput = styled(PhoneInput)(
  ({
    theme,
    error,
    disabled,
    $inputStyle,
    $error,
    $small,

    $minHeight,
    ...rest
  }) => {
    return {
      ...FontStyles.medium14,
      padding: theme.spacing(1.962, 2.5),
      alignItems: $minHeight && "flex-start",
      paddingTop: $minHeight && Spacing(2.5),
      marginTop: Spacing(1.5),
      multiline: true,
      minHeight: $minHeight && Spacing(20),
      textOverflow: "ellipsis",
      color: error ? "red" : theme.palette.text.primary,
      backgroundColor: theme.palette.primary.contrastText,
      borderBottom: "0px",
      borderRadius: theme.spacing(0.6),
      "& .PhoneInputCountry": {
        position: "relative",
        display: "flex",
        alignItems: "center",
      },
      "& .PhoneInputCountrySelect": {
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        zIndex: 1,
        border: 0,
        opacity: 0,
        cursor: "pointer",
      },

      "& .PhoneInputCountryIcon": {
        width: "28px",
        display: "flex",
      },
      "& .PhoneInputCountrySelectArrow": {
        marginRight: "8px",
        marginLeft: "8px",
        marginTop: "-4px",
        display: "block",
        content: "''",
        width: "11px",
        height: "11px",

        borderStyle: "solid",
        borderColor: "var(--PhoneInputCountrySelectArrow-color, currentColor)",
        borderTopWidth: 0,
        borderBottomWidth:
          "var(--PhoneInputCountrySelectArrow-borderWidth, 1px)",
        borderLeftWidth: 0,
        borderRightWidth:
          "var(--PhoneInputCountrySelectArrow-borderWidth, 1px)",
        transform:
          "var(--PhoneInputCountrySelectArrow-transform, rotate(45deg))",
        opacity: "var(--PhoneInputCountrySelectArrow-opacity, 0.45)",
      },

      "& .PhoneInputInput": {
        ...FontStyles.medium14,

        border: "none",
        textOverflow: "ellipsis",
        color: error ? "red" : "black",
        backgroundColor: "transparent",
        display: "flex",
        width: "100%",
        "&:focus-visible": {
          outline: "none",
        },
      },

      "& .PhoneInputInput:focus-visible": {
        outline: "none",
      },

      ...$inputStyle,
    };
  }
);

const ErrorMessage = styled(FormHelperText)(({ theme }) => {
  return {
    color: "red",
    minHeight: Spacing(2.5),
  };
});

function getErrorClassname(base, error, valid) {
  const className = base;
  if (error) return `${className} invalid`;
  if (valid) return `${className} valid`;
  return className;
}

function getHint(error) {
  if (error) return error;

  return null;
}
export interface Props extends FieldProps<any> {
  id?: string;
  label?: string;
  disableError?: boolean;
  hideVisualError?: boolean;
  type?: string;
  withHide?: () => void;
  placeholder?: string;
  state?: boolean;
  readOnly?: boolean;
  maxLength?: number;
  endButton?: (onChange: (event: any, value: any) => void, value: any) => void;

  multiline?: boolean;
  name: string;
  customBlur?: any;
  disabled?: boolean;
  isBrasil?: boolean;
}

function InputPhone(props: Props) {
  const { value, error, onBlur, ref, valid, onChange, ffHandler } =
    useFormFull.field(props);

  const {
    id,
    label,
    required,
    disableError,
    hideVisualError,

    placeholder,

    readOnly,

    multiline,
    name,
    customBlur,
    disabled,
    isBrasil = true,
  } = props;

  React.useEffect(() => {
    if (label) {
      const test = document.querySelector(".form-input-" + name)?.children;
      if (test) {
        const a = Array.from(test as HTMLCollectionOf<HTMLElement>).find(
          (element: any) => {
            if (element?.localName === "fieldset") {
              return element;
            } else {
              return null;
            }
          }
        );

        if (a) {
          a.style.paddingLeft = Spacing(3) + "px";
        }
      }
    }
  }, [label]);

  return (
    <FormControl
      className={getErrorClassname("form-control", error, valid)}
      fullWidth
      error={hideVisualError ? undefined : Boolean(error)}
    >
      {label && (
        <LabelView>
          <Label
            disabled={disabled}
            className={"form-input-label-" + name}
            $withValue={Boolean(value) || value === 0 || Boolean(placeholder)}
            $withError={Boolean(error)}
          >
            {label}
          </Label>
        </LabelView>
      )}
      <StyledInput
        defaultCountry={isBrasil && "BR"}
        international={false}
        disabled={disabled}
        multiline={multiline}
        $minHeight={multiline}
        error={error}
        id={id}
        name={name}
        value={value}
        ref={ref}
        required={!!required}
        placeholder={placeholder}
        onChange={(value) => {
          onChange(null, value);
        }}
        onBlur={async (event) => {
          const hasError = await ffHandler?.testFieldError(name, true);
          console.log(ffHandler, hasError);
          if (!hasError && customBlur) {
            let inputValue = ffHandler?.getValue(name, true);
            customBlur(inputValue);
          }
        }}
        readOnly={readOnly}
        className={"form-input-" + label?.replace(/\s/g, "")}
      />

      {disableError ? null : (
        <ErrorMessage error={Boolean(error)}>{getHint(error)}</ErrorMessage>
      )}
    </FormControl>
  );
}

export default withTheme(InputPhone);
