import React, { useState } from "react";
import { CustomText, Input, InputTags } from "../../../components/index";
import { Grid } from "@material-ui/core";
import { hooks, masks, validations } from "../../../utils";
import { fonts } from "../../../config";
import Styles from "../styles/Styles";
import { FormFull, FormFullData } from "form-full";
import { customModal } from "../../../components/modals/utils";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import "../../../phone.css";
import { LabelView } from "../../../components/inputs/Input";
import { MyLabel } from "../../registryReservation/components/ModalRegistry";
import { api } from "../../../services";
import InputPhone from "../../../components/inputs/InputPhone";

function ModalRegistry({
  data,
  ownersList,
  isManager,
  onSubmit,
}: {
  data?: any;
  isManager: boolean;
  ownersList: any[];
  onSubmit?: any;
}) {
  const selectedOwners = isManager ? ownersList : data?.users || [];
  const selectedStudios = data?.places || [];
  const { loading, call } = hooks.useRequest();
  const [placeList, setPlaceList] = useState(
    isManager
      ? ownersList[0]?.places?.map((item) => {
          return { label: item?.name, value: item?.id };
        })
      : ownersList
          ?.filter((owner) =>
            selectedOwners.some((ownerz) => ownerz.id === owner.value)
          )
          .flatMap((owner) => owner.places)
  );

  const filteredPlaceList = placeList?.filter((place) =>
    selectedStudios.some((studio) => studio.id === place.value)
  );

  const editSP = async (
    values: FormFullData<{
      name: string;
      cnpj: string;
      responsibleName: string;
      phone: string;
      email: string;
    }>
  ) => {
    let submitData = {
      id: data.id,
      email: values.email,
      name: values.name,
      responsibleName: values.responsibleName,
      cnpj: values.cnpj,
      phone: values.phone,
    };
    call(null, api.putServiceProvider(submitData), (response) => {
      if (response.ok) {
        onSubmit();
        customModal.close();
      }
    });
  };

  const createSP = async (
    values: FormFullData<{
      name: string;
      cnpj: string;
      responsibleName: string;
      phone: string;
      email: string;
    }>
  ) => {
    let submitData = {
      email: values.email,
      name: values.name,
      responsibleName: values.responsibleName,
      cnpj: values.cnpj,
      phone: values.phone,
    };
    call(null, api.postServiceProvider(submitData), (response) => {
      if (response.ok) {
        onSubmit();
        customModal.close();
      }
    });
  };

  function handleSubmit(dados) {
    if (data) {
      editSP(dados);
    } else {
      createSP(dados);
    }
  }
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          minWidth: "clamp(300px, 20vw, 650px)",
          paddingInline: "25px",
        }}
      >
        <FormFull onSubmit={handleSubmit}>
          <CustomText
            style={{ marginBottom: "20px" }}
            fontFamily={fonts.bold}
            fontSize={14 / 8}
            textColor="#B2B2B2"
          >
            DADOS BÁSICOS DA PRESTADORA DE SERVIÇO:
          </CustomText>
          <Grid alignItems="flex-end" container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <Input
                name="name"
                label="Nome da Empresa"
                defaultValue={data?.name || ""}
                validation={validations.isValidFullname}
                required={"*Campo Obrigatório"}
                white
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Input
                name="cnpj"
                label="CNPJ"
                defaultValue={data?.cnpj || ""}
                mask={masks.CNPJ}
                validation={validations.validateCNPJ}
                maskToSubmit={masks.removeNumberMask}
                required={"*Campo Obrigatório"}
                white
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Input
                name="responsibleName"
                label="NOME RESPONSÁVEL"
                defaultValue={data?.responsibleName || ""}
                validation={validations.isValidFullname}
                required={"*Campo Obrigatório"}
                white
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <InputPhone
                name="phone"
                label="Telefone*"
                defaultValue={data?.phone || ""}
                required={"*Campo Obrigatório"}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Input
                name="email"
                label="E-mail representante"
                defaultValue={data?.email || ""}
                validation={validations.isEmailValid}
                required={"*Campo Obrigatório"}
                white
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <InputTags
                name="linkToStudios"
                label={"VINCULAR A STUDIOS "}
                options={placeList ? placeList : []}
                required={"*Campo Obrigatório"}
                defaultValue={data?.places ? filteredPlaceList : null}
                white
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "12px",
              marginTop: "40px",
              width: "100%",
            }}
          >
            <Styles.CancelButton
              fullWidth={false}
              onClick={() => {
                customModal.close();
              }}
            >
              Cancelar
            </Styles.CancelButton>
            <Styles.RegitryButton
              fullWidth={false}
              action="submit"
              disabled={loading}
            >
              Confirmar {data ? "Edição" : "Cadastro"}
            </Styles.RegitryButton>
          </div>
        </FormFull>
      </div>
    </>
  );
}

export default ModalRegistry;
