import React, { useEffect, useState } from "react";
import Styles from "./styles/Styles";
import { fonts, SVG } from "../../config";
import { Add, ExpandMore } from "@material-ui/icons";
import { customModal } from "../../components/modals/utils";
import ModalRegistry from "./components/ModalRegistry";
import { HiPencil } from "react-icons/hi2";
import {
  ButtonMenu,
  ContentFeedback,
  CustomText,
  Pagination,
  Table,
} from "../../components";
import { BsPersonFillSlash } from "react-icons/bs";
import Filters from "./components/Filters";
import { hooks, masks } from "../../utils";
import { api } from "../../services";

export default function RegisterTenant() {
  const [page, setPage] = React.useState<number>(1);
  const [filters, setFilters] = React.useState<any>(null);
  const [totalPages, setTotalPages] = useState(0);
  const { loading, call } = hooks.useRequest();
  const [placesList, setPlacesList] = useState<
    { label: string; value: string }[]
  >([]);
  const [tenantList, setTenantList] = useState<
    {
      id: number;
      name: string;
      phone: string;
      email: string;
      cpf: string;
      imovel: string;
      placeIds: string[];
      startDate: string;
      endDate: string;
    }[]
  >([]);

  useEffect(() => {
    call(
      null,
      api.getPlaces({
        page: 0,
        size: 999999,
      }),
      async (response) => {
        if (response.ok) {
          const pl: { value: string; label: string }[] = [];
          for (let index = 0; index < response?.data?.content.length; index++) {
            const element = response?.data?.content[index];
            pl.push({ value: element.id, label: element.name });
          }
          setPlacesList(pl);
        }
      }
    );
    filterManager({}, 1);
  }, []);

  const filterManager = (
    filterData: {
      name?: string;
      email?: string;
      cpf?: string;
      placeId?: string;
    },
    page: number
  ) => {
    let filters = {
      page: page ? page - 1 : 0,
      size: 10,
      ...filterData
    };

    call(
      null,
      api.getTenant(filters),

      (response) => {
        if (response.ok) {
          setTotalPages(response.data.totalPages);
          const tl: {
            id: number;
            name: string;
            phone: string;
            email: string;
            cpf: string;
            imovel: string;
            placeIds: string[];
            startDate: string;
            endDate: string;
          }[] = [];
          for (let index = 0; index < response?.data?.content.length; index++) {
            const element = response?.data?.content[index];
            const placeIds: string[] = [];
            for (let index = 0; index < element.place.length; index++) {
              const place = element.place[index];
              placeIds.push(place.id + "");
            }
            tl.push({
              id: element.id,
              name: element.name,
              phone: element.phone,
              email: element.email,
              cpf: element.cpf,
              imovel: element.place[0].name,
              placeIds: placeIds,
              startDate: element.startDate,
              endDate: element.endDate,
            });
          }
          setTenantList(tl);
          setPage(page ? page : 0);
          setFilters(filterData);
        }
      }
    );
  };

  function deleteTenant(tenandId) {
    call(
      null,
      api.deleteTenant({
        id: tenandId,
      }),
      async (response) => {
        if (response.ok) {
          filterManager({}, 1);
        }
      }
    );
  }

  const openRegisterModal = () => {
    customModal.setInfos(
      "Cadastrar Inquílino",
      [],
      null,
      null,
      <ModalRegistry placeList={placesList} />,
      false,
      true,
      <SVG.Maintenance />
    );
  };

  const openEditModal = (data) => {
    customModal.setInfos(
      "Editar Dados do Inquílino",
      [],
      null,
      null,
      <ModalRegistry placeList={placesList} data={data} onSubmit={data} />,
      false,
      true,
      <HiPencil />
    );
  };

  const RevokeModalAccess = (id: string) => {
    customModal.setInfos(
      "Revogar Acesso",
      ["Continuar e revogar acesso deste inquílino?"],
      {
        label: "Sim, Revogar",
        onClick: () => {
          deleteTenant(id);
          customModal.close();
        },
      },
      {
        label: "Voltar",
        onClick: () => {
          customModal.close();
        },
      },
      null,
      null,
      null,
      <BsPersonFillSlash />
    );
  };

  const getButtons = (item: any): any[] => {
    return [
      {
        label: "Editar Dados do Inquílino",
        icon: HiPencil,
        onClick: () => openEditModal(item),
      },
      {
        label: "Revogar Acesso",
        icon: BsPersonFillSlash,
        onClick: () => RevokeModalAccess(item.id),
      },
    ];
  };

  return (
    <>
      <Styles.PageNameContainer>
        <Styles.PageNameContent>
          <CustomText fontSize={2} textColor="#FFFFFF" fontFamily={fonts.bold}>
            Cadastrar Inquílino
          </CustomText>
          <CustomText
            style={{ paddingRight: "8px" }}
            fontSize={1.75}
            textColor="#FFFFFF"
            fontFamily={fonts.regular}
          >
            Alguns dados básicos serão solicitados para o cadastro de um novo
            inquílino.
          </CustomText>
        </Styles.PageNameContent>
        <Styles.RegitryButton
          startIcon={<Add />}
          fullWidth={false}
          onClick={() => openRegisterModal()}
        >
          Cadastrar
        </Styles.RegitryButton>
      </Styles.PageNameContainer>

      <Styles.ContainerFilter>
        <CustomText
          style={{ paddingBottom: "8px" }}
          fontSize={2}
          textColor="#4D585A"
          fontFamily={fonts.bold}
        >
          Filtrar Inquílinos Cadastrados
        </CustomText>
        <Filters
          onSubmit={(data) => {
            filterManager(data, 1);
          }}
          loading={false}
          placeList={placesList}
        />
      </Styles.ContainerFilter>

      <Styles.Container>
        <CustomText
          style={{ paddingBottom: "8px" }}
          fontSize={2}
          textColor="#4D585A"
          fontFamily={fonts.bold}
        >
          Lista de Inquílinos Cadastrados
        </CustomText>
        <ContentFeedback data={tenantList} loading={loading}>
          <Table
            lastAlign={false}
            data={tenantList}
            renderItemColumns={(item) => [
              item.name,
              item.phone,
              item.email,
              item.imovel,
              masks.formatDate(item.startDate) +
                " - " +
                masks.formatDate(item.endDate),
              null,
              <ButtonMenu endIcon={<ExpandMore />} buttons={getButtons(item)}>
                Opções
              </ButtonMenu>,
            ]}
            headers={{
              table: [
                "NOME COMPLETO",
                "TELEFONE",
                "E-MAIL",
                "IMÓVEL",
                "ESTADIA",
                "",
                "AÇÃO",
              ],
              keys: [],
            }}
          />
          <Pagination
            style={{ padding: "16px 20px", paddingTop: 0 }}
            page={page}
            totalPages={totalPages}
            setPage={(number) => filterManager(filters, number)}
          />
        </ContentFeedback>
      </Styles.Container>
    </>
  );
}
