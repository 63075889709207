import React, { useContext, useState } from "react";
import Styles from "./styles/Styles";
import { fonts, SVG } from "../../config";
import { Add, Email, ExpandMore } from "@material-ui/icons";
import Filters from "./components/Filters";
import { hooks, masks } from "../../utils";
import { customModal } from "../../components/modals/utils";
import ModalRegistry from "./components/ModalRegistry";
import {
  HiCheck,
  HiMiniHandRaised,
  HiMiniNoSymbol,
  HiPencil,
} from "react-icons/hi2";
import { IoMdTrash } from "react-icons/io";
import {
  ButtonMenu,
  ContentFeedback,
  CustomText,
  Pagination,
  Table,
} from "../../components";
import { api } from "../../services";
import { StorageContext } from "../../contexts/StorageContext";
const PAGE_SIZE = 8;
export default function SteakhouseRentalPage() {
  const [page, setPage] = React.useState<number>(0);
  const [filters, setFilters] = React.useState<any>(null);
  const [places, setPlaces] = React.useState<any>(null);
  const [isMounted, setMount] = React.useState<boolean>(false);
  const { loading, call } = hooks.useRequest();
  const { userData } = useContext<any>(StorageContext);
  const Mount = React.useCallback(() => {
    getPubReservations({
      page: page,
      size: PAGE_SIZE,
    });
  }, []);

  function getPubReservations(filter:any){
    call(
      null,
      api.getPubReservations(filter),
      async (response) => {
        if (response.ok) {
          setPlaces(response?.data);
        }
      }
    );
  }

  React.useEffect(() => {
    if (!isMounted) {
      Mount();
      setMount(true);
    }
  }, [Mount, isMounted]);
  function addOneDay(date: Date): Date {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);
    return newDate;
  }
  const Submit = async (filterData: any, page?: number) => {
    let filters: any = {
      page: page ? page : 0,
      size: PAGE_SIZE,
      name: filterData?.name || null,
      cpf: filterData?.cpf || null,
      email: filterData?.email || null,
      maxEndDate: filterData?.maxEndDate
        ? masks.dateToSubmit(addOneDay(filterData?.maxEndDate))
        : null,
      minStartDate: filterData?.minStartDate
        ? masks.dateToSubmit(filterData?.minStartDate)
        : null,
    };

    filters = Object.fromEntries(
      Object.entries(filters).filter(([_, value]) => value !== null)
    );

    call(null, api.getPubReservations(filters), async (response) => {
      if (response.ok) {
        setPlaces(response?.data);
        setPage(page ? page : 0);
        setFilters(filterData);
      }
    });
  };

  const openRegisterModal = () => {
    customModal.setInfos(
      "Novo Agendamento de Churrasqueira / Pub",
      [],
      null,
      null,
      <ModalRegistry relaod={() => Submit(null, 0)} />,
      false,
      true,
      <SVG.ClanedarHourIcon />
    );
  };

  const openAprovalModal = (data) => {
    customModal.setInfos(
      "Aprovar Agendamento",
      [
        "Tem certeza que deseja aprovar este agendamento da churrasqueira / pub?",
      ],
      {
        label: "Sim, Aprovar Agendamento",
        onClick: () => {
          call("", api.putAprovePubReservations(data.id), (response) => {
            if (response.ok) {
              customModal.close();
              Submit(null, 0);
            }
          });
        },
      },
      {
        label: "Voltar",
        onClick: () => {
          customModal.close();
        },
      },
      null,
      null,
      null,
      <IoMdTrash />
    );
  };

  const openReprovalModal = (data) => {
    customModal.setInfos(
      "Reprovar Agendamento",
      [
        "Tem certeza que deseja reprovar este agendamento da churrasqueira / pub?",
      ],
      {
        label: "Sim, Reprovar Agendamento",
        onClick: () => {
          call("", api.putDenyPubReservations(data.id), (response) => {
            if (response.ok) {
              customModal.close();
              Submit(null, 0);
            }
          });
        },
      },
      {
        label: "Voltar",
        onClick: () => {
          customModal.close();
        },
      },
      null,
      null,
      null,
      <IoMdTrash />
    );
  };

  const openPendingModal = (data) => {
    customModal.setInfos(
      "Deixar Pendente este Agendamento",
      [
        "Tem certeza que deseja deixar pendente este agendamento da churrasqueira / pub?",
      ],
      {
        label: "Sim, deixe Pendente este Agendamento",
        onClick: () => {
          customModal.close();
        },
      },
      {
        label: "Voltar",
        onClick: () => {
          customModal.close();
        },
      },
      null,
      null,
      null,
      <IoMdTrash />
    );
  };

  const openEditModal = (data) => {
    customModal.setInfos(
      "Editar Dados do Agendamento",
      [],
      null,
      null,
      <ModalRegistry data={data} relaod={() => Submit(null, 0)} />,
      false,
      true,
      <HiPencil />
    );
  };

  const RemoveModal = (id: string) => {
    customModal.setInfos(
      "Remover Agendamento",
      [
        "Tem certeza que deseja remover este agendamento da churrasqueira / pub?",
      ],
      {
        label: "Sim, Remover Agendamento",
        onClick: () => {
          customModal.close();
        },
      },
      {
        label: "Voltar",
        onClick: () => {
          customModal.close();
        },
      },
      null,
      null,
      null,
      <IoMdTrash />
    );
  };

  const getButtons = (item: any): any[] => {
    if (item.status === "AP") {
      return [
        {
          label: "Reprovar",
          icon: HiMiniNoSymbol,
          onClick: () => openReprovalModal(item),
        },
        /*  {
          label: "Deixar pendente",
          icon: HiMiniHandRaised,
          onClick: () => openPendingModal(item)
        },
        {
          label: "Editar Dados do Agendamento",
          icon: HiPencil,
          onClick: () => openEditModal(item)
        },
        {
          label: (
            <div style={{ color: "#FF0000" }}>{"Remover Agendamento"}</div>
          ),
          icon: (props) => (
            <IoMdTrash {...props} style={{ color: "#FF0000" }} />
          ),
          onClick: () => RemoveModal(item.id)
        } */
      ];
    } else if (item.status === "RE") {
      return [
        {
          label: "Aprovar",
          icon: HiCheck,
          onClick: () => openAprovalModal(item),
        },
        /*  {
          label: "Deixar pendente",
          icon: HiMiniHandRaised,
          onClick: () => openPendingModal(item)
        },
        {
          label: "Editar Dados do Agendamento",
          icon: HiPencil,
          onClick: () => openEditModal(item)
        },
        {
          label: (
            <div style={{ color: "#FF0000" }}>{"Remover Agendamento"}</div>
          ),
          icon: (props) => (
            <IoMdTrash {...props} style={{ color: "#FF0000" }} />
          ),
          onClick: () => RemoveModal(item.id)
        } */
      ];
    } else {
      return [
        {
          label: "Aprovar",
          icon: HiCheck,
          onClick: () => openAprovalModal(item),
        },
        {
          label: "Reprovar",
          icon: HiMiniNoSymbol,
          onClick: () => openReprovalModal(item),
        },
        /*  {
          label: "Editar Dados do Agendamento",
          icon: HiPencil,
          onClick: () => openEditModal(item)
        },
        {
          label: (
            <div style={{ color: "#FF0000" }}>{"Remover Agendamento"}</div>
          ),
          icon: (props) => (
            <IoMdTrash {...props} style={{ color: "#FF0000" }} />
          ),
          onClick: () => RemoveModal(item.id)
        } */
      ];
    }
  };

  const getStatus = (item: string): string => {
    switch (item) {
      case "PE":
        return "Pendente";
      case "AP":
        return "Aprovado";
      case "RE":
        return "Recusado";
      default:
        return "-";
    }
  };

  const getPlace = (item: string): string => {
    switch (item) {
      case "CHURRASQUEIRA_1":
        return "Churrasqueira 1";
      case "CHURRASQUEIRA_2":
        return "Churrasqueira 2";
      case "CHURRASQUEIRA":
        return "Churrasqueira";
      case "PUB":
        return "PUB";
      default:
        return "-";
    }
  };

  return (
    <>
      <Styles.PageNameContainer>
        <Styles.PageNameContent>
          <CustomText fontSize={2} textColor="#FFFFFF" fontFamily={fonts.bold}>
            Agendar Locação da Churrasqueira / Pub
          </CustomText>
          <CustomText
            style={{ paddingRight: "8px" }}
            fontSize={1.75}
            textColor="#FFFFFF"
            fontFamily={fonts.regular}
          >
            Alguns dados básicos serão solicitados para o agendamento de uma
            nova locação da churrasqueira / pub.
          </CustomText>
        </Styles.PageNameContent>
        <Styles.RegitryButton
          loading={loading}
          disabled={loading}
          startIcon={<Add />}
          fullWidth={false}
          onClick={() => openRegisterModal()}
        >
          Novo Agendamento
        </Styles.RegitryButton>
      </Styles.PageNameContainer>

      <Styles.ContainerFilter>
        <CustomText
          style={{ paddingBottom: "8px" }}
          fontSize={2}
          textColor="#4D585A"
          fontFamily={fonts.bold}
        >
          Filtrar Locações da Churrasqueira / Pub
        </CustomText>
        <Filters onSubmit={(data) => Submit(data, 0)} loading={loading} />
      </Styles.ContainerFilter>

      <Styles.Container>
        <CustomText
          style={{ paddingBottom: "8px" }}
          fontSize={2}
          textColor="#4D585A"
          fontFamily={fonts.bold}
        >
          Lista de Locações da Churrasqueira / Pub
        </CustomText>
        <ContentFeedback data={places?.content} loading={false}>
          <Table
            lastAlign={false}
            data={places?.content}
            renderItemColumns={(item) => {
              const have = userData?.ROLES?.includes("ROLE_ADMIN_HABITAT");
              if (!have) {
                return [
                  item.name,
                  item.cpf,
                  item.email,
                  masks.formatDate(item.startDate),

                  getStatus(item.status),
                  getPlace(item.type),
                ];
              } else {
                return [
                  item.name,
                  masks.CPF(item.cpf),
                  item.email,
                  masks.formatDate(item.startDate),

                  getStatus(item.status),
                  getPlace(item.type),

                  <ButtonMenu
                    endIcon={<ExpandMore />}
                    buttons={getButtons(item)}
                  >
                    Opções
                  </ButtonMenu>,
                ];
              }
            }}
            headers={{
              table: userData?.ROLES?.includes("ROLE_ADMIN_HABITAT")
                ? [
                    "NOME RESPONSÁVEL",
                    "CPF",
                    "E-MAIL",
                    "DATA",

                    "Status",
                    "Tipo",

                    "AÇÃO",
                  ]
                : [
                    "NOME RESPONSÁVEL",
                    "CPF",
                    "E-MAIL",
                    "DATA",

                    "Status",
                    "Tipo",
                  ],
              keys: [],
            }}
          />
          <Pagination
            style={{ padding: "16px 20px", paddingTop: 0 }}
            page={page + 1}
            totalPages={places?.totalPages || 1}
            setPage={(number) => {
              setPage(number-1);
              getPubReservations({
                page: number-1,
                size: PAGE_SIZE,
              });
            }}
          />
        </ContentFeedback>
      </Styles.Container>
    </>
  );
}
