import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";

const StyledCheckboxIcon = styled.span`
  width: 24px;
  height: 24px;
  background: #4d585a 0% 0% no-repeat padding-box;
  border-radius: 5px;
`;

const StyledCheckedCheckboxIcon = styled.span`
  width: 24px;
  height: 24px;
  background: #7e7e7e00 0% 0% no-repeat padding-box;
  border: 2px solid #ffffff;
  border-radius: 5px;
  position: relative;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
  }
`;

const SquareCheckbox = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  icon: {
    borderRadius: 0
  },
  checkedIcon: {
    borderRadius: 0
  }
})((props: CheckboxProps) => (
  <Checkbox
    disableRipple
    color="default"
    checkedIcon={<StyledCheckedCheckboxIcon />}
    icon={<StyledCheckboxIcon />}
    {...props}
  />
));

export default SquareCheckbox;
