import React from "react";

import ptBr from "../../../config/texts/pt-br";
import {
  ButtonText,
  CustomText,
  Input,
  InputAutocomplete,
  InputDate,
} from "../../../components/index";
import Styles from "../styles";
import { FormFull } from "form-full";

import { Grid } from "@material-ui/core";

function Filters({ onSubmit, loading }) {
  const texts = ptBr.login;

  const statusOptions = [
    { label: "Todos", value: "" },
    { label: "Foto Pendente", value: "PA" },
    { label: "Acesso Liberado", value: "AL" },
    { label: "Acesso Finalizado", value: "AF" },
    { label: "Acesso Recusado", value: "AR" },
    { label: "Acesso Cancelado", value: "AC" },
  ];

  return (
    <FormFull onSubmit={(data) => onSubmit(data)}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={6}>
          <Input name="name" label="Nome do Imóvel" disableError />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <InputAutocomplete
            name="reservationStatus"
            label="Status"
            options={statusOptions}
          />
        </Grid>
      </Grid>
      <Styles.ButtonContainer>
        <Styles.Clear
          loading={loading}
          disabled={loading}
          fullWidth={false}
          action="clear"
        >
          Limpar
        </Styles.Clear>
        <Styles.Submit
          loading={loading}
          disabled={loading}
          fullWidth={false}
          action="submit"
        >
          Aplicar Filtros
        </Styles.Submit>
      </Styles.ButtonContainer>
    </FormFull>
  );
}

export default Filters;
