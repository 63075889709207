import React, { useState } from "react";
import {
  CustomText,
  Input,
  InputDate,
  InputRadios,
} from "../../../components/index";
import { IoNewspaperSharp } from "react-icons/io5";
import { Grid } from "@material-ui/core";
import { hooks, masks, validations } from "../../../utils";
import { fonts, Spacing, SVG } from "../../../config";
import Styles from "../styles/Styles";
import { FormFull } from "form-full";
import { customModal } from "../../../components/modals/utils";
import { AccessTime } from "@material-ui/icons";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import "../../../phone.css";
import { LabelView } from "../../../components/inputs/Input";
import { MyLabel } from "../../registryReservation/components/ModalRegistry";
import InputCheckbox from "../../../components/inputs/InputCheckbox";
import { ModalTerms } from "./ModalTerms";
import { api } from "../../../services";
import InputPhone from "../../../components/inputs/InputPhone";

function ModalRegistry({ data, relaod }: { data?: any; relaod: () => void }) {
  const phone = React.useRef<any>();
  const [checked, setChecked] = useState(false);
  const [formRef, setFormRef] = useState<any>(undefined);
  const { loading, call } = hooks.useRequest();
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setChecked(checked);
  };

  function setPhone(value) {
    phone.current = value;
  }
  function handleSubmit(data) {
    var newData = {
      name: data?.personName,
      email: data?.email,
      phone: data.phone,
      cpf: data?.cpf,
      type: data?.type,
      startDate: masks.dateToSubmit(data.date) + " 00:00",
      endDate: masks.dateToSubmit(data.date) + " 23:59",
    };
    call("", api.postNewPubReservations(newData), (response) => {
      if (response.ok) {
        customModal.close();
        relaod();
      }
    });
  }

  React.useEffect(() => {
    if (data) {
      setPhone(data.phone);
    }
  }, []);

  const openTermosModal = () => {
    const formData = formRef.getValues();
    formData.phone = phone.current;
    console.log(formData);
    customModal.close();
    customModal.setInfos(
      "Visualizar Termos de Uso",
      [
        "Abaixo, visualize os Termos de Uso e Locação da Churrasqueira (ou PUB):",
      ],
      null,
      {
        onX: true,
        onClick: () => {
          openRegisterModal(formData);
        },
      },
      <ModalTerms type={formData?.type} />,
      false,
      true,
      false
    );
  };
  const openRegisterModal = (oldData: any) => {
    customModal.setInfos(
      "Novo Agendamento de Churrasqueira / Pub",
      [],
      null,
      null,
      <ModalRegistry relaod={relaod} data={oldData} />,
      false,
      true,
      <SVG.ClanedarHourIcon />
    );
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          minWidth: "clamp(300px, 20vw, 650px)",
          paddingInline: "25px",
        }}
      >
        <FormFull formRef={setFormRef} onSubmit={handleSubmit}>
          <CustomText
            style={{ marginBottom: "20px" }}
            fontFamily={fonts.bold}
            fontSize={14 / 8}
            textColor="#B2B2B2"
          >
            DADOS DO RESPONSÁVEL E PERÍODO
          </CustomText>
          <Grid alignItems="flex-end" container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <InputRadios
                name="type"
                label="TIPO DE LOCAÇÃO*"
                white
                type="circle"
                required={"*Campo Obrigatório"}
                defaultValue={data?.type || null}
                options={[
                  {
                    value: "CHURRASQUEIRA_1",
                    label: "Churrasqueira 1 - R$ 60,00",
                  },
                  {
                    value: "CHURRASQUEIRA_2",
                    label: "Churrasqueira 2 - R$ 60,00",
                  },
                  { value: "PUB", label: "PUB - R$ 180,00" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Input
                name="personName"
                label="NOME COMPLETO"
                defaultValue={data?.personName || ""}
                validation={validations.isValidFullname}
                required={"*Campo Obrigatório"}
                white
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Input
                required={"*Campo Obrigatório"}
                name="cpf"
                defaultValue={data?.cpf || ""}
                validation={validations.validateCPF}
                mask={masks.CPF}
                maskToSubmit={masks.removeNumberMask}
                label="CPF"
                white
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Input
                name="email"
                label="E-mail"
                defaultValue={data?.email || ""}
                validation={validations.isEmailValid}
                required={"*Campo Obrigatório"}
                white
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <InputPhone
                placeholder="99 99999-9999"
                name="phone"
                label="Telefone"
                defaultValue={data?.phone || ""}
                required={"*Campo Obrigatório"}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <InputDate
                label="DATA"
                onBlur={() => null}
                onChange={() => null}
                name="date"
                customValidation={() => null}
                defaultValue={data?.date || ""}
                minDate={new Date()}
                required={"*Campo Obrigatório"}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <InputCheckbox
                name="accept"
                label="Li e aceito os termos de uso"
                white
                value={checked}
                onChange={handleChange}
                required
                error={!checked ? "Campo Obrigatório" : ""}
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-between",
              marginTop: "40px",
            }}
          >
            <button
              onClick={openTermosModal}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
                padding: "0px",
                margin: "0px",
                color: "#ffffff",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                width: "100%",
                fontFamily: fonts.regular,
                fontSize: "12px",
                textDecoration: "underline",
              }}
            >
              <IoNewspaperSharp />{" "}
              <p style={{ cursor: "pointer" }}>Visualizar Termos de Uso</p>
            </button>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "12px",
                width: "100%",
              }}
            >
              <Styles.CancelButton
                loading={loading}
                disabled={loading}
                fullWidth={false}
                onClick={() => {
                  customModal.close();
                }}
              >
                Cancelar
              </Styles.CancelButton>
              <Styles.RegitryButton
                loading={loading}
                disabled={loading || !checked}
                fullWidth={false}
                action="submit"
              >
                Confirmar {data ? "Edição" : "Agendamento"}
              </Styles.RegitryButton>
            </div>
          </div>
        </FormFull>
      </div>
    </>
  );
}

export default ModalRegistry;
