import React, { useContext } from "react";
// import { navigate } from "../../../navigation/navigate";
import Styles from "../styles/Styles";
import { customModal } from "../../../components/modals/utils";
import { fonts, pagesConfig, Spacing, SVG } from "../../../config";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { CustomText, FontStyles } from "../../../components";
import { StorageContext } from "../../../contexts/StorageContext";
import { hasAccess } from "../../../App";

type CustomMenuGroupProps = {
  item: any;
  firstItem: boolean;
  headerName: string;
  user: any;
};

function MenuGroupWithPagesComponent({
  item,
  firstItem,
  headerName,
  user,
}: CustomMenuGroupProps) {
  const classes = Styles.useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { logOut } = useContext<any>(StorageContext);

  function handleLogout() {
    customModal.setInfos(
      "Sair",
      [],
      {
        label: "Sim, Sair",
        onClick: () => {
          customModal.close();

          logOut();
        },
      },
      {
        label: "Voltar",
        onClick: () => {
          customModal.close();
        },
      },
      <div
        style={{
          paddingTop: Spacing(2),
          paddingBottom: Spacing(4.75),
          paddingInline: Spacing(3.12),
        }}
      >
        <CustomText
          fontSize={14 / 8}
          fontFamily={fonts.regular}
          textColor="white"
        >
          Sair do sistema e encerrar sua sessão?
        </CustomText>
      </div>,
      null,
      null,
      <SVG.Exit />
    );
  }

  const goto = (path: string, title: string) => {
    let link;
    console.log("navigate "+path);
    if (!isDisabled(path) === false) {
      if (title === "Sair") {
        handleLogout();
      } else {
        navigate(path);
      }
    } else {
      link = null;
    }
    return link;
  };

  function isDisabled(path: string) {
    let a = false;
    pagesConfig.logged.map((item) => {
      return item.pages.map((element: any) => {
        if (path === element?.path) {
          if (element?.working !== ( null)) {
            a = element?.working;
          } else {
            a = false;
          }
        }
        return element;
      });
    });
    return a;
  }

  return (
    <Styles.Group $firstOne={firstItem}>
      <Styles.GroupTitleContainer>
        <Styles.GroupNameContainer>
          <Styles.GroupTitle>{headerName}</Styles.GroupTitle>
        </Styles.GroupNameContainer>
      </Styles.GroupTitleContainer>
      <Styles.SubGroup>
        {item.map((page: any, pageIndex: number) => {          
          const canAccess = hasAccess(user?.ROLES, page?.roles);
          if (canAccess) {
            return (
              <Styles.PageLine
                key={pageIndex + page?.name}
                $currentPage={page.path === location?.pathname}
              >
                <Styles.Page
                  $buttonContained={false}
                  $currentPage={page.path === location?.pathname}
                  $disabled={!isDisabled(page.path)}
                  key={page.name + pageIndex}
                  onClick={() => goto(page.path, page.title)}
                >
                  <Styles.PageContent
                    $currentPage={page.path === location?.pathname}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {page.icon ? (
                        <page.icon alt="" className={classes.icon} />
                      ) : null}
                      {page.title}
                    </div>

                    {page.title === "Reservas" &&
                      user &&
                      user.newReservations > 0 && (
                        <a
                          style={{
                            backgroundColor: "white",
                            paddingInline: "12px",
                            paddingBlock: "5px",
                            color: "#24272A",
                            borderRadius: "16px",
                            ...FontStyles.semibold12,
                          }}
                        >
                          {user.newReservations} NOVAS!
                        </a>
                      )}
                  </Styles.PageContent>
                </Styles.Page>
              </Styles.PageLine>
            );
          } else {
            return null;
          }
        })}
      </Styles.SubGroup>
    </Styles.Group>
  );
}

export default MenuGroupWithPagesComponent;
