import React from "react";
import Styles from "../styles/Styles";
import Drawer from "@material-ui/core/Drawer";
import { CircularProgress, IconButton, makeStyles } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { CustomText, DefaultLogo } from "../../../components";
import { Colors, fonts, pagesConfig, Spacing } from "../../../config";
import styled from "styled-components";

import { Close } from "@material-ui/icons";
import { MenuGroupWithPages } from "../components";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  drawerPaper: {
    borderRight: "none",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function Menu({ openMenu, isOpen, user }) {
  const classes = useStyles();
  const drawer = (
    <>
      <Styles.Container>
        <Styles.ScrollContainer>
          <Styles.Header>
            <DefaultLogo white maxWidth={Spacing(10)} />
            <Styles.Close size="small" onClick={() => openMenu(!isOpen)}>
              <Close />
            </Styles.Close>
          </Styles.Header>

          {pagesConfig ? (
            pagesConfig.logged?.map((item: any, index) => {
              if (item.dontShow === false) {
                return (
                  <MenuGroupWithPages
                    user={user}
                    key={item.name + index}
                    headerName={item.groupTitle}
                    item={item.pages}
                    firstItem={index === 0}
                  />
                );
              }
            })
          ) : (
            <div
              style={{
                width: "96%",
                marginTop: "10%",
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          )}
        </Styles.ScrollContainer>
        <CustomText
          fontSize={1.5}
          fontFamily={fonts.medium}
          textColor={Colors.whiteBackground}
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBlock: Spacing(3),
          }}
        >
          {process.env.REACT_APP_ENV + " v"}
          {process.env.REACT_APP_VERSION}
        </CustomText>
      </Styles.Container>
    </>
  );

  const MyHidden = styled(Hidden)(() => {
    return {};
  });

  return (
    <Styles.ShowQuery>
      <nav aria-label="mailbox folders">
        <MyHidden mdUp={true} implementation="css">
          <Drawer
            variant="temporary"
            anchor={"left"}
            open={isOpen}
            onClose={() => openMenu(!isOpen)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </MyHidden>
        <MyHidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor={"left"}
            variant="permanent"
            open
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </MyHidden>
      </nav>
    </Styles.ShowQuery>
  );
}

export default Menu;
