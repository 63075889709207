import React from "react";
import { FormFull } from "form-full";
import Styles from "../styles/";
import { Grid } from "@material-ui/core";
import {
  CustomText,
  Input,
  InputAutocomplete,
  InputDate,
} from "../../../components/index";
import { Label } from "../../../components/inputs/Input";
import { fonts, Spacing } from "../../../config";
import { addOneDay } from "../../registerTenant/components/ModalRegistry";
import { masks, validations } from "../../../utils";
import { AccessTime } from "@material-ui/icons";

function Filters({ onSubmit, loading, placeList }) {
  const [formRef, setFormRef] = React.useState<any>(null);
  const [minDate, setMinDate] = React.useState<any>(null);

  return (
    <FormFull formRef={setFormRef} onSubmit={(data) => onSubmit(data)}>
      <Grid alignItems="flex-end" container spacing={1}>
        <Grid item xs={6} md={4} lg={2}>
          <div style={{ paddingTop: "9px" }}>
            <Input name="name" label="IMÓVEL" placeholder="IMÓVEL" />
          </div>
        </Grid>
        <Grid container item xs={6} md={4} lg={2}>
          <Label>DATA SOLICITAÇÃO</Label>
          <InputDate
            onChange={(value) => {
              console.log(value);
            }}
            name="solicitationDate"
          />
        </Grid>
        <Grid container item xs={6} md={4} lg={2}>
          <Label>ESTADIA</Label>
          <InputDate
            onChange={(value) => {
              setMinDate(value);
              const end = formRef?.getValue("endDate");
              console.log(end, value, formRef);
              if (end < value) {
                formRef?.clearValue("endDate", false);
              }
            }}
            name="startDate"
          />
        </Grid>
        <Grid container item xs={6} md={4} lg={2}>
          <Label>até</Label>
          <InputDate minDate={addOneDay(minDate)} name="endDate" />
        </Grid>
        <Grid item xs={6} md={6} lg={1}>
          <div style={{ padding: "5px" }}></div>
          <Label>Check-in</Label>
          <Input
            mask={masks.formatHour}
            placeholder="00:00"
            validation={(value: any, ffHandler: any) => {
              const errorRange = validations.validateTimeRange(value);

              if (errorRange == null) {
                const startValue = ffHandler.getValue("startDate", false);
                console.log(startValue);
                if (startValue && startValue != "") {
                  return null;
                } else {
                  return "Selecione uma estadia antes";
                }
                return null;
              } else {
                return errorRange;
              }
            }}
            name="checkInTime"
            endAdornment={<AccessTime style={{ fontSize: Spacing(2) }} />}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={1}>
          <div style={{ padding: "5px" }}></div>
          <Label>Check-out</Label>
          <Input
            mask={masks.formatHour}
            placeholder="00:00"
            validation={(value: any, ffHandler: any) => {
              const errorRange = validations.validateTimeRange(value);

              if (errorRange == null) {
                const startValue = ffHandler.getValue("endDate", false);
                if (startValue && startValue != "") {
                  return null;
                } else {
                  return "Selecione uma estadia antes";
                }
                return null;
              } else {
                return errorRange;
              }
            }}
            name="checkOutTime"
            endAdornment={<AccessTime style={{ fontSize: Spacing(2) }} />}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={1}>
          <div style={{ paddingTop: "9px" }}>
            <Input
              name="nhospedes"
              label="Nº Hóspedes"
              placeholder="Nº Hóspedes"
            />
          </div>
        </Grid>
        <Grid item xs={6} md={4} lg={1}>
          <div style={{ paddingTop: "9px" }}>
            <InputAutocomplete
              readOnly={false}
              name="status"
              label="Status"
              options={placeList ? placeList : []}
            />
          </div>
        </Grid>
      </Grid>
      <Styles.ButtonContainer>
        <Styles.Clear
          loading={loading}
          disabled={loading}
          fullWidth={false}
          action="clear"
        >
          Limpar
        </Styles.Clear>
        <Styles.Submit
          loading={loading}
          disabled={loading}
          fullWidth={false}
          action="submit"
        >
          Aplicar Filtros
        </Styles.Submit>
      </Styles.ButtonContainer>
    </FormFull>
  );
}

export default Filters;
