import React, { useContext, useState } from "react";
import {
  ButtonMenu,
  ContentFeedback,
  CustomText,
  Pagination,
  Table,
} from "../../components";
import { fonts, SVG } from "../../config";
import Styles from "./styles/Styles";
import { Add, Close, ExpandMore, Visibility } from "@material-ui/icons";
import Filters from "./components/Filters";
import { hooks, masks, SessionStorage } from "../../utils";
import { customModal } from "../../components/modals/utils";
import { BsPersonFillSlash } from "react-icons/bs";
import { HiPencil } from "react-icons/hi2";
import ModalRegistry from "./components/ModalRegistry";
import { api } from "../../services";

import { StorageContext } from "../../contexts/StorageContext";
import { CircularProgress } from "@material-ui/core";
import { hasAccess } from "../../App";

export interface ManagerProps {
  id: string;
  email: string;
  name: string;
  cpf: string;
  places: {
    externalId: string;
    id: string;
    name: string;
  }[];
}

export interface OwnersProps {
  id: string;
  email: string;
  name: string;
  cpf: string;
  places: {
    externalId: string;
    id: string;
    name: string;
  }[];
}
const PAGE_SIZE = 8;

function MangerRegistryPage() {
  const { loading, call } = hooks.useRequest();
  const { userData } = useContext<any>(StorageContext);
  const [page, setPage] = React.useState<number>(0);
  const [filters, setFilters] = React.useState<any>(null);
  const [managerList, setManagerList] = useState<ManagerProps[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [ownersList, setOwnersList] = useState<
    { label: string; value: string; places: { id: string; name: string }[] }[]
  >([]);
  const [isManager, setIsManager] = useState(false);

  const Mount = React.useCallback(() => {    

    const canAccess = hasAccess(userData?.ROLES, ["ROLE_ADMIN_HABITAT"]);

    filterManager({}, 0);

    if (canAccess) {
      call(
        null,
        api.getOwners({
          page: 0,
          size: 999999,
          cpf: "",
          email: "",
          name: "",
        }),
        async (response) => {
          if (response.ok) {
            const list = response?.data?.content?.map((item) => {
              return {
                label: item.email,
                value: item.id,
                places: item.places.map((place) => {
                  return {
                    label: place.name,
                    value: place.id,
                  };
                }),
              };
            });
            setOwnersList(list);
          }
        }
      );
    } else {
      setIsManager(true);
      call(
        null,
        api.getPlaces({
          page: 0,
          size: 999999,
        }),
        async (response) => {
          if (response.ok) {
            setOwnersList([
              {
                label: "Gestor",
                value: userData?.id,
                places: response?.data?.content,
              },
            ]);
          }
        }
      );
    }
  }, []);

  React.useEffect(() => {
    Mount();
  }, [Mount]);

  const deleteManager = async (id: string) => {
    call(null, api.deleteManagers({ id }), (response) => {
      if (response.ok) {
        filterManager(filters, 0);
        customModal.close();
      }
    });
  };

  const filterManager = (
    filterData: {
      fullName?: string;
      email?: string;
      cpf?: string;
    },
    page: number
  ) => {
    let filters = {
      page: page ? page : 0,
      size: PAGE_SIZE,
    };
    if (filterData?.fullName) {
      filters["name"] = filterData.fullName;
    }
    if (filterData?.email) {
      filters["email"] = filterData.email;
    }
    if (filterData?.cpf) {
      filters["cpf"] = filterData.cpf;
    }

    call(
      null,
      api.getManagers(filters),

      (response) => {
        if (response.ok) {
          setManagerList(response?.data?.content);
          setTotalPages(response?.data?.totalPages);
          setPage(page ? page : 0);
          setFilters(filterData);
        }
      }
    );
  };

  const getButtons = (item: any): any[] => {
    if (isManager) {
      return [
        {
          label: "Visualizar Dados",
          icon: Visibility,
          onClick: () => openViewModal(item),
        },
        {
          label: "Editar Dados",
          icon: HiPencil,
          onClick: () => openEditModal(item),
        },
        {
          label: "Revogar Gestor",
          icon: BsPersonFillSlash,
          onClick: () => openDeleteModal(item?.id),
        },
      ];
    } else {
      return [
        {
          label: "Visualizar Dados",
          icon: Visibility,
          onClick: () => openViewModal(item),
        },
        {
          label: "Editar Dados",
          icon: HiPencil,
          onClick: () => openEditModal(item),
        },

        {
          label: "Deletar Gestor",
          icon: BsPersonFillSlash,
          onClick: () => openDeleteModal(item.id),
        },
      ];
    }
  };

  const openRegisterModal = () => {
    customModal.setInfos(
      "Cadastrar Gestor",
      [],
      null,
      null,
      <ModalRegistry
        isManager={isManager}
        ownersList={ownersList}
        filterManager={() => filterManager(filters, page)}
      />,
      false,
      true,
      <SVG.addUser />
    );
  };

  const openEditModal = (manager) => {
    if (manager) {
      customModal.setInfos(
        "Editar Dados do Gestor",
        [],
        null,
        null,
        <ModalRegistry
          isManager={isManager}
          ownersList={ownersList}
          data={manager}
          filterManager={() => filterManager(filters, page)}
        />,
        false,
        true,
        <Visibility />
      );
    }
  };

  const openViewModal = (manager) => {
    if (manager) {
      customModal.setInfos(
        "Ver Dados do Gestor",
        [],
        null,
        null,
        <ModalRegistry
          viewOnly={true}
          isManager={isManager}
          ownersList={ownersList}
          data={manager}
        />,
        false,
        true,
        <Visibility />
      );
    }
  };

  const openDeleteModal = (id: string) => {
    customModal.setInfos(
      isManager ? "Revogar Acessos" : "Deletar Gestor",
      isManager
        ? ["Continuar e revogar acesso deste gestor?"]
        : ["Continuar e deletar acesso deste gestor?"],
      {
        label: "Continuar",
        onClick: () => {
          deleteManager(id);
        },
      },
      {
        label: "Voltar",
        onClick: () => {
          customModal.close();
        },
      },
      null,
      false,
      true,
      <Close />
    );
  };

  return (
    <>
      <Styles.PageNameContainer>
        <Styles.PageNameContent>
          <CustomText fontSize={2} textColor="#FFFFFF" fontFamily={fonts.bold}>
            Cadastrar Gestor
          </CustomText>

          <CustomText
            style={{ paddingRight: "8px" }}
            fontSize={1.75}
            textColor="#FFFFFF"
            fontFamily={fonts.regular}
          >
            Alguns dados básicos serão solicitados para o cadastro de um novo
            gestor.
          </CustomText>
        </Styles.PageNameContent>

        <Styles.RegitryButton
          startIcon={<Add />}
          fullWidth={false}
          onClick={openRegisterModal}
          disabled={loading}
          loading={loading}
        >
          Cadastrar
        </Styles.RegitryButton>
      </Styles.PageNameContainer>

      <Styles.ContainerFilter>
        <CustomText
          style={{ paddingBottom: "25px" }}
          fontSize={2}
          textColor="#4D585A"
          fontFamily={fonts.bold}
        >
          Filtrar Gestores Cadastrados
        </CustomText>
        <Filters
          onSubmit={(data) => {
            filterManager(data, 0);
          }}
          loading={loading}
        />
      </Styles.ContainerFilter>

      <Styles.Container>
        <CustomText
          style={{ paddingBottom: "8px" }}
          fontSize={2}
          textColor="#4D585A"
          fontFamily={fonts.bold}
        >
          Lista de Gestores Cadastrados
        </CustomText>

        <ContentFeedback data={managerList} loading={false}>
          {loading ? (
            <Styles.ContainerList>
              <CircularProgress />
            </Styles.ContainerList>
          ) : (
            <>
              <Table
                lastAlign={false}
                data={managerList}
                renderItemColumns={(item) => [
                  item.name,
                  item.email,
                  masks.CPF(item.cpf),
                  null,
                  null,
                  <ButtonMenu
                    endIcon={<ExpandMore />}
                    buttons={getButtons(item)}
                  >
                    Opções
                  </ButtonMenu>,
                ]}
                headers={{
                  table: ["NOME COMPLETO", "EMAIL", "CPF", "", "", "AÇÃO"],
                  keys: [],
                }}
              />
              <Pagination
                style={{ padding: "16px 20px", paddingTop: 0 }}
                page={page + 1}
                totalPages={totalPages}
                setPage={(number) => {
                  filterManager(filters, number - 1);
                }}
              />
            </>
          )}
        </ContentFeedback>
      </Styles.Container>
    </>
  );
}

export default MangerRegistryPage;
