import React from "react";

import ptBr from "../../../config/texts/pt-br";
import {
  ButtonText,
  CustomText,
  Input,
  InputAutocomplete,
  InputDate,
} from "../../../components/index";
import Styles from "../styles";
import { FormFull } from "form-full";
import { hooks, masks } from "../../../utils";

import { fonts, Spacing, SVG } from "../../../config";
import { Grid } from "@material-ui/core";
import { customModal } from "../../../components/modals/utils";
import { Visibility } from "@material-ui/icons";
import { api } from "../../../services";
import LogExpand from "./LogExpand";

function ModalView({ item }) {
  const createButton = (item) => {
    return <div></div>;
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          minWidth: "clamp(300px, 54vw, 1055px)",
          paddingInline: "25px",
          marginBottom: "20px",
          overflow: "auto",
        }}
      >
        {item.map((i, index) => (
          <LogExpand item={i} index={index} />
        ))}
      </div>
    </>
  );
}

export default ModalView;
