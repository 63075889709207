import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText
} from "@material-ui/core";
import styled from "styled-components";
import { withTheme } from "@material-ui/styles";
import SquareCheckbox from "../others/SquareCheckbox";

const Label = styled.p(({ theme, $withError, white }) => {
  const { palette: colors } = theme;
  return {
    textTransform: "uppercase",
    padding: 0,
    margin: 0,
    color: $withError ? colors.error.main : white ? "white" : "white",
    transition: ".2s",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center"
  };
});

const ErrorMessage = styled(FormHelperText)(({ theme }) => {
  return {
    color: "red",
    minHeight: theme.spacing(2.5)
  };
});

interface Props {
  id?: string;
  label?: string;
  name: string;
  value?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  error?: string;
  white?: boolean;
  required?: boolean;
}

const InputCheckbox: React.FC<Props> = ({
  id,
  label,
  name,
  value = false,
  onChange,
  error,
  white,
  required
}) => {
  return (
    <FormControl
      component="fieldset"
      error={Boolean(error)}
      style={{ marginTop: "10px" }}
    >
      <FormControlLabel
        control={
          <SquareCheckbox
            checked={value}
            onChange={(event) => onChange?.(event, event.target.checked)}
          />
        }
        label={
          label && (
            <Label $withError={Boolean(error)} white={white}>
              {label}
              {required && "*"}
            </Label>
          )
        }
        style={{ alignItems: "center" }}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </FormControl>
  );
};

export default withTheme(InputCheckbox);
