import {
  ButtonMenu,
  ContentFeedback,
  CustomText,
  Pagination,
  Table,
} from "../../components/index";
import Styles from "./styles";

import { masks } from "../../utils";
import { hooks } from "../../utils";

import { fonts, SVG } from "../../config";

import { Check, Close, ExpandMore, Mail, Visibility } from "@material-ui/icons";
import { customModal } from "../../components/modals/utils";

import ModalView from "./components/ModalView";
import { api } from "../../services";
import React from "react";

const PAGE_SIZE = 8;

function Monitoring() {
  const [page, setPage] = React.useState<number>(0);
  const { loading, call } = hooks.useRequest();
  const [monitoring, setMonitoring] = React.useState<any>(null);

  const [isMounted, setMount] = React.useState<boolean>(false);
  const actions = [
    { id: "RESERVATION_CHECK_IN", label: "Check-in realizado" },
    { id: "RESERVATION_CHECK_OUT", label: "Check-out realizado" },
    { id: "TUYA_PASSWORD_CREATE", label: "Senha Tuya criada" },
    {
      id: "TUYA_VISITANT_RESERVATION_PASSWORD_CREATE",
      label: "Senha para visitante criada (Tuya)",
    },
    {
      id: "TUYA_TENANT_RESERVATION_PASSWORD_CREATE",
      label: "Senha para morador criada (Tuya)",
    },
    {
      id: "TUYA_VISITANT_RESERVATION_PASSWORD_DELETE",
      label: "Senha de visitante excluída (Tuya)",
    },
    {
      id: "TUYA_TENANT_RESERVATION_PASSWORD_DELETE",
      label: "Senha de morador excluída (Tuya)",
    },
    {
      id: "TUYA_SERVICE_PROVIDER_RESERVATION_PASSWORD_CREATE",
      label: "Senha para prestador de serviço criada (Tuya)",
    },
    {
      id: "TUYA_SERVICE_PROVIDER_RESERVATION_PASSWORD_DELETE",
      label: "Senha de prestador de serviço excluída (Tuya)",
    },
    {
      id: "TUYA_PASSWORD_WHATSAPP_MESSAGE",
      label: "Senha enviada via WhatsApp (Tuya)",
    },
    {
      id: "TUYA_VISITANT_PASSWORD_WHATSAPP_MESSAGE",
      label: "Senha de visitante enviada via WhatsApp (Tuya)",
    },
    { id: "TUYA_PASSWORD_EMAIL", label: "Senha enviada via e-mail (Tuya)" },
    {
      id: "TUYA_VISITANT_PASSWORD_EMAIL",
      label: "Senha de visitante enviada via e-mail (Tuya)",
    },
    {
      id: "TUYA_TENANT_PASSWORD_EMAIL",
      label: "Senha de morador enviada via e-mail (Tuya)",
    },
    {
      id: "TUYA_SERVICE_PROVIDER_PASSWORD_EMAIL",
      label: "Senha de prestador de serviço enviada via e-mail (Tuya)",
    },
    { id: "TUYA_PASSWORD_EDIT", label: "Senha editada (Tuya)" },
    { id: "TUYA_PASSWORD_DELETE", label: "Senha excluída (Tuya)" },
    { id: "SEGWARE_DELETE_RENTER", label: "Locatário excluído (Segware)" },
    {
      id: "SEGWARE_FACIAL_RENTER",
      label: "Reconhecimento facial de hóspede realizado (Segware)",
    },
    {
      id: "SEGWARE_FACIAL_VISITANT_RESERVATION",
      label: "Reconhecimento facial de visitante realizado (Segware)",
    },
    {
      id: "SEGWARE_FACIAL_TENANT_RESERVATION",
      label: "Reconhecimento facial de morador realizado (Segware)",
    },
    {
      id: "SEGWARE_FACIAL_SERVICE_PROVIDER_RESERVATION",
      label:
        "Reconhecimento facial de prestador de serviço realizado (Segware)",
    },
    { id: "SEGWARE_SAVE_RENTER", label: "Hóspede salvo (Segware)" },
    { id: "SEGWARE_EDIT_RENTER", label: "Hóspede editado (Segware)" },
    {
      id: "SEGWARE_VISITANT_EDIT_RENTER",
      label: "Visitante editado (Segware)",
    },
    { id: "SEGWARE_TENANT_EDIT_RENTER", label: "Morador editado (Segware)" },
    {
      id: "SEGWARE_SERVICE_PROVIDER_EDIT_RENTER",
      label: "Prestador de serviço editado (Segware)",
    },
  ];

  const Mount = React.useCallback(async () => {
    call(
      null,
      api.getMonitoringList({ page: 0, size: PAGE_SIZE, sort: "id,desc" }),
      async (response) => {
        if (response.ok) {
          setMonitoring(response.data);
        }
      }
    );
  }, []);

  React.useEffect(() => {
    if (!isMounted) {
      Mount();
      setMount(true);
    }
  }, [Mount, isMounted]);

  const Submit = async (page?: number) => {
    let filters = {
      page: page ? page : 0,
      size: PAGE_SIZE,
      sort: "id,desc",
    };

    call(null, api.getMonitoringList(filters), async (response) => {
      if (response.ok) {
        setMonitoring(response?.data);
        setPage(page ? page : 0);
      }
    });
  };

  const openModalView = (item) => {
    customModal.setInfos(
      "Visualizar Dados",
      [],
      {
        onClick: () => {
          customModal.close();
        },
        label: "Voltar",
      },
      null,
      <ModalView item={item.apiLogs} />,
      false,
      true,
      <Visibility />
    );
  };

  const getButtons = (item: any): any[] => {
    return [
      {
        label: "Visualizar Dados",
        icon: Visibility,
        onClick: () => {
          openModalView(item);
        },
      },
    ];
  };
  function convertToBrazilTime(dateString) {
    const date = new Date(dateString);
    const adjustedDate = new Date(date.getTime() - 3 * 60 * 60 * 1000);
    return adjustedDate.toISOString();
  }
  return (
    <>
      <Styles.Container>
        <CustomText
          style={{ padding: "16px 20px" }}
          fontSize={2}
          textColor="#4D585A"
          fontFamily={fonts.bold}
        >
          Lista de Monitoramentos
        </CustomText>
        <ContentFeedback data={monitoring?.content} loading={loading}>
          <Table
            lastAlign={false}
            data={monitoring?.content}
            renderItemColumns={(item) => [
              item?.id,
              actions.find((i) => i.id == item?.type)?.label || "-",
              masks.formatDate(item?.editedAt) +
                " - " +
                masks.formatDateHour(convertToBrazilTime(item?.editedAt)),
              masks.formatDate(item?.createdAt) +
                " - " +
                masks.formatDateHour(convertToBrazilTime(item?.createdAt)),
              <ButtonMenu endIcon={<ExpandMore />} buttons={getButtons(item)}>
                Opções
              </ButtonMenu>,
            ]}
            headers={{
              table: [
                "ID TAREFA",
                "TIPO TAREFA",
                "DATA / HORA ENVIO",
                "DATA / HORA CRIAÇÃO TAREFA",
                "AÇÃO",
              ],
              keys: [],
            }}
          />
          <Pagination
            style={{ padding: "16px 20px", paddingTop: 0 }}
            page={page + 1}
            totalPages={monitoring?.totalPages}
            setPage={(number) => {
              Submit(number - 1);
            }}
          />
        </ContentFeedback>
      </Styles.Container>
    </>
  );
}

export default Monitoring;
