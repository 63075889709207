import React, { useContext, useState } from "react";
import Styles from "./styles/Styles";
import { fonts, SVG } from "../../config";
import {
    Add,
    ExpandMore,
    Lock,
    Public,
    Visibility,
} from "@material-ui/icons";
import Filters from "./components/Filters";
import { hooks, masks } from "../../utils";
import { customModal } from "../../components/modals/utils";
import ModalRegistry from "./components/ModalRegistry";
import ModalView from "./components/ModalView";
import { HiPencil } from "react-icons/hi2";
import ResendAccessModal from "./components/ResendAccessModal";
import { FaKey } from "react-icons/fa6";
import {
    ButtonMenu,
    ContentFeedback,
    CustomText,
    Pagination,
    Table,
} from "../../components";
import { BsPersonFillSlash } from "react-icons/bs";
import { api } from "../../services";
import { StorageContext } from "../../contexts/StorageContext";
import { hasAccess } from "../../App";

export default function RegisterServiceProvider() {
    const [page, setPage] = React.useState<number>(0);
    const [totalPages, setTotalPages] = useState(0);
    const [filters, setFilters] = React.useState<any>(null);
    const { userData } = useContext<any>(StorageContext);
    const { loading, call } = hooks.useRequest();
    const PAGE_SIZE = 8;
    const [serviceProviders, setServiceProviders] = React.useState<any>(null);
    const [isMounted, setMount] = React.useState<boolean>(false);
      const [isManager, setIsManager] = useState(false);
        const [ownersList, setOwnersList] = useState<
          { label: string; value: string; places: { id: string; name: string }[] }[]
        >([]);
    
  const Mount = React.useCallback(() => {
    call(null, api.getServiceProvider({
        page: page,
        size: PAGE_SIZE,
      }), async (response) => {
        if (response.ok) {
            setPage(page ? page : 0);
            setTotalPages(response?.data?.totalPages);
            setServiceProviders(response?.data?.content);
        }
      }
    );
    const canAccess = hasAccess(userData?.ROLES, ["ROLE_ADMIN_HABITAT"]);
    
        if (canAccess) {
          call(
            null,
            api.getOwners({
              page: 0,
              size: 999999,
              cpf: "",
              email: "",
              name: "",
            }),
            async (response) => {
              if (response.ok) {
                const list = response?.data?.content?.map((item) => {
                  return {
                    label: item.email,
                    value: item.id,
                    places: item.places.map((place) => {
                      return {
                        label: place.name,
                        value: place.id,
                      };
                    }),
                  };
                });
                setOwnersList(list);
              }
            }
          );
        } else {
          setIsManager(true);
          call(
            null,
            api.getPlaces({
              page: 0,
              size: 999999,
            }),
            async (response) => {
              if (response.ok) {
                setOwnersList([
                  {
                    label: "Gestor",
                    value: userData?.id,
                    places: response?.data?.content,
                  },
                ]);
              }
            }
          );
        }
  }, []);

  const Submit = async (
    filterData: { name?: string; cnpj?: string; email?: string },
    page?: number
  ) => {
    let filters = {
      page: page ? page : 0,
      size: PAGE_SIZE,
    };

    if (filterData?.name) {
      filters["name"] = filterData.name;
    }
    if (filterData?.cnpj) {
      filters["cnpj"] = filterData.cnpj;
    }
    if (filterData?.email) {
      filters["email"] = filterData.email;
    }

    call(null, api.getServiceProvider(filters), async (response) => {
      if (response.ok) {
        setServiceProviders(response?.data?.content);
        setPage(page ? page : 0);
        setTotalPages(response?.data?.totalPages);
        setFilters(filterData);
      }
    });
  };



  React.useEffect(() => {
    if (!isMounted) {
      Mount();
      setMount(true);
    }
  }, [Mount, isMounted]);


    const openRegisterModal = () => {
        customModal.setInfos(
            "Cadastrar Prestadora de Serviço",
            [
                "Ao continuar e confirmar o cadastro desta empresa prestadora de serviço, enviaremos um e-mail contendo a chave de acesso o e-mail do responsável informado:",
            ],
            null,
            null,
            <ModalRegistry 
            isManager={isManager}
            ownersList={ownersList} onSubmit={()=>{Mount()}}/>,
            null,
            null,
            <SVG.Maintenance />
        );
    };

    const openModalView = (item: any) => {
        customModal.setInfos(
            "Visualizar Dados da Prestadora de Serviço",
            [],
            {
                onClick: () => {
                    customModal.close();
                },
                label: "Voltar",
            },
            null,
            <ModalView item={item} />,
            null,
            null,
            <Visibility />
        );
    };

    const openEditModal = (data) => {
        customModal.setInfos(
            "Editar Dados da Prestadora de Serviço",
            [],
            null,
            null,
            <ModalRegistry 
            isManager={isManager}
            ownersList={ownersList} data={data} onSubmit={()=>{Mount()}}/>,
            null,
            null,
            <HiPencil />
        );
    };

    const ResendModal = (item: any) => {
        customModal.setInfos(
            "Reenviar Chave de Acesso",
            ["Continuar e reenviar a chave de acesso para esta prestadora?"],
            {
                onClick: () => {
                    customModal.close();
                },
                label: "Sim, Reenviar",
            },
            {
                onClick: () => {
                    customModal.close();
                },
                label: "Voltar",
            },
            <ResendAccessModal item={item} />,
            null,
            null,
            <FaKey />
        );
    };

    const RevokeModalAccess = (id: string) => {
        customModal.setInfos(
            "Revogar Acesso",
            [
                "Ao revogar o acesso deste imóvel, a empresa prestadora de serviço terá sua chave de acesso atual desativada. Continuar e revogar acesso?",
            ],
            {
                label: "Sim, Revogar",
                onClick: () => {
                    customModal.close();
                },
            },
            {
                label: "Voltar",
                onClick: () => {
                    customModal.close();
                },
            },
            null,
            null,
            null,
            <BsPersonFillSlash />
        );
    };

    function checkPermission(item:any){
        return hasAccess(userData?.ROLES, ["ROLE_ADMIN_HABITAT"])||userData.id===item.createdBy.id;
    }

    const getButtons = (item: any): any[] => {
        return checkPermission(item)?[
            {
                label: "Visualizar Dados da Prestadora de Serviço",
                icon: Visibility,
                onClick: () => openModalView(item),
            },
            {
                label: "Editar Dados da Prestadora de Serviço",
                icon: HiPencil,
                onClick: () => openEditModal(item),
            },
            {
                label: "Reenviar Chave de Acesso",
                icon: FaKey,
                onClick: () => ResendModal(item),
            },
            {
                label: "Revogar Acesso",
                icon: BsPersonFillSlash,
                onClick: () => RevokeModalAccess(item.id),
            },
        ]:
        [{
            label: "Visualizar Dados da Prestadora de Serviço",
            icon: Visibility,
            onClick: () => openModalView(item),
        },
        {
            label: "Reenviar Chave de Acesso",
            icon: FaKey,
            onClick: () => ResendModal(item),
        },
        {
            label: "Revogar Acesso",
            icon: BsPersonFillSlash,
            onClick: () => RevokeModalAccess(item.id),
        },];
    };

    function StatusVisibility(item: string) {
        switch (item) {
            case "PRIVADO":
            case "Privado":
            case "PRIVATE":
            case "Private":
                return (
                    <>
                        <Styles.Visibility $visibility={item}>
                            <Lock fontSize="small" />
                            {"Privado"}
                        </Styles.Visibility>
                    </>
                );

            case "GLOBAL":
            case "Global":
                return (
                    <>
                        <Styles.Visibility $visibility={item}>
                            <Public fontSize="small" />
                            {"Global"}
                        </Styles.Visibility>
                    </>
                );
            default:
                return null;
        }
    }

    return (
        <>
            <Styles.PageNameContainer>
                <Styles.PageNameContent>
                    <CustomText fontSize={2} textColor="#FFFFFF" fontFamily={fonts.bold}>
                        Cadastrar Prestadora de Serviço
                    </CustomText>
                    <CustomText
                        style={{ paddingRight: "8px" }}
                        fontSize={1.75}
                        textColor="#FFFFFF"
                        fontFamily={fonts.regular}
                    >
                        Alguns dados básicos serão solicitados para o cadastro de uma nova
                        empresa prestadora de serviço.
                    </CustomText>
                </Styles.PageNameContent>
                <Styles.RegitryButton
                    startIcon={<Add />}
                    fullWidth={false}
                    onClick={() => openRegisterModal()}
                >
                    Cadastrar
                </Styles.RegitryButton>
            </Styles.PageNameContainer>

            <Styles.ContainerFilter>
                <CustomText
                    style={{ paddingBottom: "8px" }}
                    fontSize={2}
                    textColor="#4D585A"
                    fontFamily={fonts.bold}
                >
                    Filtrar Prestadoras de Serviço Cadastradas
                </CustomText>
                <Filters onSubmit={Submit} loading={loading} />
            </Styles.ContainerFilter>

            <Styles.Container>
                <CustomText
                    style={{ paddingBottom: "8px" }}
                    fontSize={2}
                    textColor="#4D585A"
                    fontFamily={fonts.bold}
                >
                    Filtrar Prestadoras de Serviço Cadastradas
                </CustomText>
                <ContentFeedback data={serviceProviders} loading={false}>
                    <Table
                        lastAlign={false}
                        data={serviceProviders}
                        renderItemColumns={(item) => [
                            item.name,
                            masks.CNPJ(item.cnpj),
                            item.email,
                            StatusVisibility(item.type),
                            null,
                            null,
                            <ButtonMenu endIcon={<ExpandMore />} buttons={getButtons(item)}>
                                Opções
                            </ButtonMenu>,
                        ]}
                        headers={{
                            table: [
                                "NOME DA EMPRESA",
                                "CNPJ",
                                "E-MAIL REPRESENTANTE",
                                "NÍVEL DE VISIBILIDADE",
                                "",
                                "",
                                "AÇÃO",
                            ],
                            keys: [],
                        }}
                    />
                    <Pagination
                        style={{ padding: "16px 20px", paddingTop: 0 }}
                        page={page + 1}
                        totalPages={totalPages}
                        setPage={(number) => {
                        Submit(filters, number - 1);
                        }}
                    />
                </ContentFeedback>
            </Styles.Container>
        </>
    );
}
