import React, { useState } from "react";

import ptBr from "../../../config/texts/pt-br";
import {
  ButtonText,
  CustomText,
  FontStyles,
  Input,
  InputAutocomplete,
  InputDate,
} from "../../../components/index";
import Styles from "../styles";
import { FormFull } from "form-full";
import { hooks, masks } from "../../../utils";

import { fonts, Spacing, SVG } from "../../../config";
import { Grid } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
function HeadItem({ title, value, link }) {
  return (
    <div
      style={{
        display: "flex",
        gap: "6px",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <CustomText
        textColor="#FFFFFF"
        fontFamily={fonts.semibold}
        fontSize={1.75}
      >
        {title}
      </CustomText>
      <CustomText
        style={{ borderBottom: "1px solid white", paddingBottom: "8px" }}
        textColor="#FFFFFF"
        fontFamily={fonts.medium}
        fontSize={1.5}
      >
        {link ? (
          <a style={{ color: "#C2C2C2", ...FontStyles.medium12 }} href={value}>
            {value}
          </a>
        ) : (
          value
        )}
      </CustomText>
    </div>
  );
}

function ExpandItem({ title, value }) {
  return (
    <div
      style={{
        display: "flex",
        gap: "10px",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        width: "100%",
        paddingTop: "10px",
      }}
    >
      <CustomText
        textColor="#FFFFFF"
        fontFamily={fonts.semibold}
        fontSize={1.75}
      >
        {title}
      </CustomText>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "5px",
          padding: "14px",
          flex: 1,
          width: "100%",
          overflowWrap: "anywhere",
        }}
      >
        <CustomText
          textColor="#4D585A"
          fontFamily={fonts.medium}
          fontSize={1.5}
        >
          {value}
        </CustomText>
      </div>
    </div>
  );
}

function LogExpand({ item, index }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",

          padding: "24px",
          backgroundColor: "#383B3E",
          overflow: "auto",

          borderRadius: "5px",
          marginBottom: "10px",
        }}
      >
        <CustomText
          style={{ paddingBottom: "14px" }}
          textColor="#C2C2C2"
          fontFamily={fonts.semibold}
          fontSize={1.5}
        >
          REQUISIÇÃO #{index + 1}
        </CustomText>

        <Grid
          alignItems="stretch"
          justifyContent="center"
          container
          spacing={2}
        >
          <Grid item xs={6}>
            <HeadItem link title="URL de Envio" value={item.url} />
          </Grid>
          <Grid item xs={3}>
            <HeadItem
              link={false}
              title="Método de Envio"
              value={item.method}
            />
          </Grid>
          <Grid item xs={2}>
            <HeadItem
              link={false}
              title="Código de Status"
              value={item.responseStatus}
            />
          </Grid>
          <Grid item xs={1}>
            <button
              style={{
                alignSelf: "center",
                background: "transparent",
                boxShadow: "none",
                padding: "10px",
                border: "none", // Garante que não tenha borda visível
                cursor: "pointer", // Mantém a usabilidade,
                color: "white",
                width: "100%",
              }}
              onClick={() => setOpen((v) => !v)}
            >
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </button>
          </Grid>
        </Grid>
        <div
          style={{
            maxHeight: open ? "500px" : "0",
            opacity: open ? 1 : 0,
            transition: "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
            overflow: "hidden",
          }}
        >
          <ExpandItem title="Corpo de Envio" value={item.requestBody} />
          <ExpandItem title="Header de Envio" value={item.requestHeaders} />
          <ExpandItem title="Corpo de Retorno" value={item.responseBody} />
          <ExpandItem title="Header de Retorno" value={item.responseHeaders} />
        </div>
      </div>
    </>
  );
}

export default LogExpand;
