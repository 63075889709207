import React, { useState, useMemo, useEffect } from "react";

export function ModalTerms({ type }: { type?: string }) {
  const [documents, setDocuments] = useState([
    {
      id: "CHURRASQUEIRA_1",
      name: "Churrasqueira 01",
      url: "/UP termo de Reserva Churrasqueira 01.pdf",
    },
    {
      name: "Churrasqueira 02",
      id: "CHURRASQUEIRA_2",
      url: "/UP termo de Reserva Churrasqueira 02.pdf",
    },
    { name: "Pub", id: "PUB", url: "/UP termo de Reserva Pub.pdf" },
  ]);

  useEffect(() => {
    if (type) {
      setDocuments((i) => i.filter((doc) => doc.id === type));
    }
  }, [type]);


  const [activeTab, setActiveTab] = useState(0);

  return (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 100px)",
        padding: "20px",
        paddingTop: 0,
        marginTop: "-20px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", marginBottom: "10px", gap: "10px" }}>
        {documents.map((doc, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(index)}
            style={{
              padding: "10px 20px",
              backgroundColor: activeTab === index ? "#007BFF" : "#f0f0f0",
              color: activeTab === index ? "white" : "black",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            {doc.name}
          </button>
        ))}
      </div>
      {documents.length > 0 ? (
        <iframe
          src={`${process.env.PUBLIC_URL}${documents[activeTab].url}`}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
          }}
        />
      ) : (
        <p>Nenhum documento disponível.</p>
      )}
    </div>
  );
}
